import React from "react";
import ReactHtmlParser, { convertNodeToElement } from "react-html-parser";

// USAGE:
// const ComponentName = () => {
//   const PComponent = styled.div``;
//   const customComponents = [
//     {
//       name: 'p',
//       Component: PComponent,
//     },
//   ];
//
//   const html = `
//     <p>
//       Paragraph
//     </p>
//   `;
//
//   return <CustomHTMLParser html={html} customComponents={customComponents} />;
// };

// https://stackoverflow.com/questions/6659351/removing-all-script-tags-from-html-with-js-regular-expression
function stripScripts(s) {
  const isClient = typeof document === "object";
  if (isClient) {
    const div = document.createElement("div");
    div.innerHTML = s;
    const scripts = div.getElementsByTagName("script");
    let i = scripts.length;
    while (i--) {
      scripts[i].parentNode.removeChild(scripts[i]);
    }
    return div.innerHTML;
  } else {
    return s.replace(/<script([\S\s]*?)<\/script>/gi, "");
  }
}

function CustomHTMLParser({ html, customComponents }) {
  const scriptlessHTML = stripScripts(html);
  const getCustomComponent = name =>
    customComponents.find(comp => comp.name === name);
  const isCustomComponent = name => {
    const c = getCustomComponent(name);
    return c && c.name === name;
  };
  function toCustomComponents(node, index) {
    if (node.type === "tag") {
      // if node is paragraph
      if (node.name && isCustomComponent(node.name)) {
        const { Component, props } = getCustomComponent(node.name);

        return (
          // return custom p tag
          <Component key={"main-scomponent-" + index} {...props}>
            {// convert nested paragraph children (em, strong, bold etc...)
            node.children.map((childNode, idx) => {
              // build custom nested children of p if exist
              if (childNode.name && isCustomComponent(childNode.name)) {
                const { Component, props } = getCustomComponent(childNode.name);
                return (
                  <Component
                    {...childNode.attribs}
                    {...props}
                    key={"component-" + idx}
                  >
                    {childNode.children[0].data}
                  </Component>
                );
              }
              // build the rest of p children
              return convertNodeToElement(childNode, idx, toCustomComponents);
            })}
          </Component>
        );
      }
    }
    return convertNodeToElement(node, index, toCustomComponents);
  }

  return ReactHtmlParser(scriptlessHTML, { transform: toCustomComponents });
}
export default CustomHTMLParser;
